export default [
    {
        to: {name: 'admin.index'},
        icon: 'mdi-home',
        text: 'general.dashboard',
    },
    {
        to: {name: 'admin.price-plans.index'},
        icon: 'mdi-application-cog',
        text: 'general.schedule',
    },
    {
        to: {name: 'admin.users.index'},
        icon: 'mdi-account',
        text: 'general.price-plans',
    },
    {
        to: {name: 'admin.orders.index'},
        icon: 'mdi-basket',
        text: 'general.orders',
    },
]
