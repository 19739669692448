<template>
    <admin-dashboard-layout>
        <v-card :loading="loading">
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ order.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.orders.index'}">{{ $t('general.back') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field :label="$t('general.id')" :placeholder="$t('general.id')" outlined v-model="order.id"
                                          disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field :label="$t('general.client-id')" :placeholder="$t('general.client-id')" outlined
                                          v-model="order.client_id" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field :label="$t('general.name')" :placeholder="$t('general.name')" outlined v-model="order.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field :label="$t('general.email')" :placeholder="$t('general.email')" outlined
                                          v-model="order.email"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field :label="$t('general.phone')" :placeholder="$t('general.phone')" outlined
                                          v-model="order.phone"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-textarea :label="$t('general.order')" :placeholder="$t('general.order')" outlined
                                        v-model="order.textarea"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field :label="$t('general.delivery-date')" :placeholder="$t('general.delivery-date')" outlined
                                          v-model="order.pickup_at"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field :label="$t('general.status')" :placeholder="$t('general.status')" outlined
                                          v-model="order.status"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field v-model="order.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0 max-width-200">
                            <v-btn block color="green" class="text--white" @click="handleSaveOrder">{{ $t('general.save-changes') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import Order from "../../../models/Order";

export default {
    name: "orders-show",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            order: {},
            loading: false,
        }
    },
    methods: {
        async handleSaveOrder() {
            //this.formValid = this.$refs.form.validate()

            //if (this.formValid) {
                let _order = new Order(this.order)
                _order = await _order.save()
                this.$emit('update', _order)
                //await this.$router.push({name: 'admin.orders.show', params: {id: this.order.id}}).catch(() => {})
                this.snackbar = true
           // }
        }
    },
    async mounted() {
        this.loading = true
        if (this.$route.params.id) {
            this.order = await Order.find(this.$route.params.id)
        }
        console.log("%cid Заказа:" + this.$route.params.id, "border-radius: 20px; padding: 2px 4px; color: #fff; background-color: green")
        this.loading = false
    },
}
</script>

<style scoped>
.text--white {
    color: white;
}

.max-width-200 {
    max-width: 200px;
}
</style>

